import { useState } from "react";
import { PlusCircle, Trash2 } from "lucide-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const API_URL = "/api"; // Change to your actual backend URL
export default function Component() {
  const navigate = useNavigate(); // Use navigate to redirect
  const [questions, setQuestions] = useState([
    { questionText: "", options: [{ optionText: "", isCorrect: false }] },
  ]);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { questionText: "", options: [{ optionText: "", isCorrect: false }] },
    ]);
  };

  const removeQuestion = (questionIndex) => {
    setQuestions(questions.filter((_, index) => index !== questionIndex));
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push({
      optionText: "",
      isCorrect: false,
    });
    setQuestions(newQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options = newQuestions[
      questionIndex
    ].options.filter((_, index) => index !== optionIndex);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (questionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].questionText = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex].optionText = value;
    setQuestions(newQuestions);
  };

  const handleCorrectToggle = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex].isCorrect =
      !newQuestions[questionIndex].options[optionIndex].isCorrect;
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Post the questions to the backend
      const response = await axios.post(`${API_URL}/QuizSetup`, { questions });

      navigate("/questionslist"); // Navigate to results page
    } catch (error) {
      console.error("Error saving questions:", error);
    }
  };
  return (
    <div className="min-h-screen bg-purple-100 p-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Dynamic Question Form
      </h1>
      {questions.map((question, questionIndex) => (
        <div
          key={questionIndex}
          className="bg-white rounded-lg shadow-md mb-6 p-6"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              Question {questionIndex + 1}
            </h2>
            <button
              onClick={() => removeQuestion(questionIndex)}
              className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </div>
          <input
            type="text"
            placeholder="Enter your question"
            value={question.questionText}
            onChange={(e) =>
              handleQuestionChange(questionIndex, e.target.value)
            }
            className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
          {question.options.map((option, optionIndex) => (
            <div key={optionIndex} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                placeholder={`Option ${optionIndex + 1}`}
                value={option.optionText}
                onChange={(e) =>
                  handleOptionChange(questionIndex, optionIndex, e.target.value)
                }
                className="flex-grow p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <div className="flex items-center space-x-2">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={option.isCorrect}
                    onChange={() =>
                      handleCorrectToggle(questionIndex, optionIndex)
                    }
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Correct
                  </span>
                </label>
              </div>
              <button
                onClick={() => removeOption(questionIndex, optionIndex)}
                className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          ))}
          <button
            onClick={() => addOption(questionIndex)}
            className="mt-2 flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            <PlusCircle className="h-4 w-4 mr-2" /> Add Option
          </button>
        </div>
      ))}
      <div className="flex justify-between mt-4">
        <button
          onClick={addQuestion}
          className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <PlusCircle className="h-4 w-4 mr-2" /> Add Question
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
        >
          Submit Form
        </button>
        <Link
          className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
          to="/questionslist"
        >
          Back
        </Link>
      </div>
    </div>
  );
}
