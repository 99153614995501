import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for API requests
import "bootstrap/dist/css/bootstrap.min.css";

const API_URL = "/api"; // Change to your actual backend URL

export default function QuizAppWithAttemptsLimit() {
  const [currentPage, setCurrentPage] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [quizLocked, setQuizLocked] = useState(false);
  const [error, setError] = useState("");
  const [results, setResults] = useState([]); // To store the results after submission
  const [userres, setResponses] = useState([]);
  const [questions, setQuestions] = useState([]);

  const questionsPerPage = 3;
  const totalPages = Math.ceil(questions.length / questionsPerPage);
  const userId = localStorage.getItem("userId");
  console.log(userId);
  // Fetch quiz attempts from the database and check if the quiz is locked
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        // Fetch quiz attempts from the database
        const attemptsResponse = await axios.get(`${API_URL}/Users/${userId}`);

        const attempts = attemptsResponse.data.attemptsRemaining;

        setAttempts(attempts);
        if (attempts >= 3) {
          setQuizLocked(true);
        }

        // Fetch quiz questions
        const response = await axios.get(`${API_URL}/QuizSetup`);
        const data = response.data.questions.$values;
        setQuestions(data);
      } catch (err) {
        setError("Error fetching quiz data.");
      }
    };

    fetchQuizData();
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prev) => ({ ...prev, [questionId]: answer }));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    questions.forEach((question) => {
      if (userAnswers[question.id] === question.correctAnswer) {
        correctAnswers++;
      }
    });
    return (correctAnswers / questions.length) * 100;
  };

  // Function to submit answers and store data in the database
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Prepare the payload to send to the server
    const payload = {
      userId: userId, // Dynamic userId
      questionResponses: Object.keys(userAnswers).map((questionId) => ({
        questionId: parseInt(questionId),
        selectedOptionId: parseInt(userAnswers[questionId]),
      })),
    };

    try {
      const response = await axios.post(`${API_URL}/Training/submit`, payload);

      // const jsonData = JSON.stringify(response.data); // Display the results after submission
      setResults(response.data.responses.$values);

      const calculatedScore = calculateScore();
      setScore(response.data.percentageScore);
      setQuizCompleted(true);

      const newAttempts = attempts + 1;
      setAttempts(newAttempts);

      // Update attempts in the database

      await axios.put(`${API_URL}/Users/${userId}`, {
        AttemptsRemaining: newAttempts,
      });

      if (newAttempts >= 3) {
        setQuizLocked(true);
      }
    } catch (err) {
      setError("Error submitting quiz.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRestart = () => {
    if (attempts < 3) {
      setCurrentPage(0);
      setUserAnswers({});
      setQuizCompleted(false);
      setScore(0);
      setError("");
      setResults(null); // Clear results on restart
    } else {
      setQuizLocked(true);
    }
  };

  const startIndex = currentPage * questionsPerPage;
  const endIndex = startIndex + questionsPerPage;
  const currentQuestions = questions.slice(startIndex, endIndex);
  const progress = ((currentPage + 1) / totalPages) * 100;

  if (quizLocked) {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body text-center">
                <h1 className="card-title mb-4">Quiz Locked</h1>
                <p className="lead">
                  You have exceeded the maximum number of attempts (3).
                </p>
                <p>Please contact the administrator for further assistance.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (quizCompleted) {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title text-center mb-4">Quiz Results</h1>
                <h2 className="text-center mb-4">
                  Your Score: {score.toFixed(2)}%
                </h2>
                {score < 50 && (
                  <div className="alert alert-danger text-center" role="alert">
                    You have failed the exam. You have {3 - attempts} attempts
                    remaining.
                  </div>
                )}
                {results.map((question) => (
                  <div key={question.id} className="mb-4">
                    <h5 className="card-subtitle mb-2">
                      {question.questionText}
                    </h5>
                    <p>
                      Your answer:{" "}
                      <strong>
                        {question.selectedOptionText || "Not answered"}
                      </strong>
                    </p>

                    {question.isCorrect ? (
                      <div className="alert alert-success">Correct!</div>
                    ) : (
                      <div className="alert alert-danger">Incorrect</div>
                    )}
                  </div>
                ))}
                <div className="text-center mt-4">
                  {attempts < 3 && (
                    <button className="btn btn-primary" onClick={handleRestart}>
                      Restart Quiz
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <h1 className="card-title text-center mb-4">Quiz Page</h1>
              <p className="text-center mb-4">Attempt {attempts + 1} of 3</p>
              <div className="progress mb-4">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  {`${Math.round(progress)}%`}
                </div>
              </div>
              {currentQuestions.map((question) => (
                <div key={question.id} className="mb-4">
                  <h5>{question.questionText}</h5>{" "}
                  {/* Correctly reference the questionText */}
                  {/* Ensure options.$values is correctly mapped */}
                  {question.options.$values.map((option, index) => (
                    <div key={index} className="form-check">
                      <input
                        type="radio"
                        name={`question${question.id}`}
                        id={`option${index}`}
                        value={option.optionText}
                        checked={userAnswers[question.id] === option.id}
                        onChange={() =>
                          handleAnswerChange(question.id, option.id)
                        }
                      />
                      <label htmlFor={`option${index}`}>
                        {option.optionText}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
              <div className="d-flex justify-content-between mt-4">
                <button
                  className="btn btn-secondary"
                  onClick={handlePrevPage}
                  disabled={currentPage === 0 || isSubmitting}
                >
                  Previous
                </button>
                {currentPage < totalPages - 1 ? (
                  <button
                    className="btn btn-primary"
                    onClick={handleNextPage}
                    disabled={isSubmitting}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    Submit Quiz
                  </button>
                )}
              </div>
              {error && (
                <div className="alert alert-danger text-center mt-4">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
