import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const API_URL = "/api"; // Change to your actual backend URL
  const login = async (username, password) => {
    // Replace this with your actual API call
    const response = await axios.post(`${API_URL}/Auth/Login`, {
      username,
      password,
    });
    console.log(response);
    if (!response.ok) throw new Error("Login failed");
    return await response.json();
  };

  const saveToken = (token) => {
    localStorage.setItem("authToken", token);
  };

  const saveId = (id) => {
    localStorage.setItem("userId", id);
  };

  const saveUserSession = (session) => {
    localStorage.setItem("sessionuser", JSON.stringify(session));
    setUser(session);
  };

  const onLogin = (data) => {
    // Any additional actions on login
    console.log("User logged in:", data);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        saveToken,
        saveUserSession,
        onLogin,
        logout,
        saveId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
