import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // To get the questionId from the URL
import axios from "axios";

const API_URL = "/api"; // Update with your actual API URL

const EditQuiz = () => {
  const { questionId } = useParams(); // Get questionId from URL
  const [questions, setQuestions] = useState([
    { questionText: "", options: [{ optionText: "", isCorrect: false }] },
  ]);
  const navigate = useNavigate();
  // Fetch the quiz data by questionId when the component mounts
  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const response = await axios.get(`${API_URL}/QuizSetup/${questionId}`);

        // Transform the response to match the format expected by your `questions` state
        const questionData = [
          {
            questionText: response.data.questionText, // From API
            options: response.data.options.$values.map((option) => ({
              id: option.id,
              optionText: option.optionText, // From API
              isCorrect: option.isCorrect, // From API
            })),
          },
        ];

        // Set the `questions` state
        setQuestions(questionData);
      } catch (error) {
        console.error("Error fetching the question:", error);
      }
    };

    fetchQuestionData();
  }, [questionId]);
  console.log(questions);
  // Function to handle changes to question text
  const handleQuestionChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].questionText = e.target.value;
    setQuestions(updatedQuestions);
  };

  // Function to handle changes to option text
  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex].optionText =
      e.target.value;
    setQuestions(updatedQuestions);
  };

  // Function to handle changes to the "isCorrect" switch
  const handleIsCorrectChange = (e, questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex].isCorrect =
      e.target.checked;
    setQuestions(updatedQuestions);
  };

  // Function to handle adding a new option to a question
  const addOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.push({
      optionText: "",
      isCorrect: false,
    });
    setQuestions(updatedQuestions);
  };

  // Function to remove an option
  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    if (updatedQuestions[questionIndex].options.length > 1) {
      updatedQuestions[questionIndex].options.splice(optionIndex, 1);
      setQuestions(updatedQuestions);
    }
  };

  // Function to handle adding a new question
  const addQuestion = () => {
    setQuestions([
      ...questions,
      { questionText: "", options: [{ optionText: "", isCorrect: false }] },
    ]);
  };

  // Function to remove a question
  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send updated questions to the backend
      const response = await axios.put(`${API_URL}/QuizSetup/${questionId}`, {
        questions,
      });
      navigate(`/questionslist`);
    } catch (error) {
      if (error.response) {
        console.error(error.response.data); // This will log any validation error details
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2>Edit Question</h2>
      <form onSubmit={handleSubmit}>
        {questions.map((question, questionIndex) => (
          <div key={questionIndex} className="card mb-4 p-3">
            <div className="form-group">
              <label>Question {questionIndex + 1}</label>
              <input
                type="text"
                className="form-control"
                value={question.questionText}
                onChange={(e) => handleQuestionChange(e, questionIndex)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label>Options</label>
              {question.options.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className="d-flex align-items-center mb-2"
                >
                  <input
                    type="text"
                    className="form-control me-2"
                    value={option.optionText}
                    onChange={(e) =>
                      handleOptionChange(e, questionIndex, optionIndex)
                    }
                    required
                  />
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`option${optionIndex}`}
                      checked={option.isCorrect}
                      onChange={(e) =>
                        handleIsCorrectChange(e, questionIndex, optionIndex)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`option${optionIndex}`}
                    >
                      Correct
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => removeOption(questionIndex, optionIndex)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={() => addOption(questionIndex)}
              >
                Add Option
              </button>
            </div>
          </div>
        ))}

        <button type="submit" className="btn btn-success w-100 mt-3">
          Save Quiz
        </button>
      </form>
    </div>
  );
};

export default EditQuiz;
