import axios from "axios";

const API_URL = "/api"; // Change to your actual backend URL

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/Auth/Login`, {
    username,
    password,
  });
  return response.data; // This will be the UserSession object
};

export const getVideo = async () => {
  try {
    const response = await axios.get(`${API_URL}/Training/video`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getQuestions = async () => {
  try {
    const response = await axios.get(`${API_URL}/QuizSetup`);
    const questions = response.questions.$values;
    console.log(questions);
    return questions;
  } catch (error) {
    console.error(error);
  }
};

export const submitQuiz = async (answers) => {
  try {
    const response = await axios.post(`${API_URL}/Training/submit`, answers);
    return response.data;
  } catch (error) {
    console.log(answers);
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const submitUser = async (newUser) => {
  try {
    const response = await axios.post(`${API_URL}/Users`, newUser);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/Users`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getResponses = async () => {
  try {
    const response = await axios.get(`${API_URL}/Training/responses`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getVideos = async () => {
  try {
    const response = await axios.get(`${API_URL}/Videos`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const submitVideo = async (newVideo) => {
  try {
    const response = await axios.post(`${API_URL}/Videos`, newVideo);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const submitEditVideo = async (id, newVideo) => {
  try {
    const response = await axios.put(`${API_URL}/Videos/${id}`, newVideo);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const submitEditUser = async (id, newUser) => {
  try {
    const response = await axios.put(`${API_URL}/Users/${id}`, newUser);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const DeleteUser = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/Users/${id}`);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const DeleteVideo = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/Videos/${id}`);
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};

export const submitQuestions = async (questions) => {
  try {
    const response = await axios.post(`${API_URL}/QuizSetup`, { questions });
    return response.data;
  } catch (error) {
    console.error("waxaa jir eeror", error.response.data);
  }
};
