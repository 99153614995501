import React, { useEffect, useState } from "react";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa"; // Icons for red cross and green tick
import "bootstrap/dist/css/bootstrap.min.css";
const ResultsPage = () => {
  const [results, setResults] = useState(null);

  useEffect(() => {
    // Fetch the results from localStorage
    const savedResults = localStorage.getItem("quizResults");
    if (savedResults) {
      setResults(JSON.parse(savedResults));
    }
  }, []);

  if (!results) {
    return <p>Loading results...</p>;
  }
  return (
    <div className="container mt-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h2 className="mb-4">Your Results</h2>

            <p className="fs-4">
              <strong>Score:</strong> {results.score}%
            </p>
            <p className="fs-5">
              <strong>Correct Answers:</strong> {results.correctAnswers} Out of{" "}
              {results.totalQuestions}
            </p>

            {results.marks ? (
              <div className="text-success">
                <FaCheckCircle size={80} />
                <p className="fs-4 mt-3">
                  Congratulations, you passed the quiz!
                </p>
              </div>
            ) : (
              <div className="text-danger">
                <FaTimesCircle size={80} />
                <p className="fs-4 mt-3">
                  You have failed the quiz. Please try again.
                </p>
                <p className="fs-5">
                  Attempts Remaining: {results.attemptsRemaining}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResultsPage;
