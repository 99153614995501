import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getVideo } from "../api/authApi";

const VideoPlayer = ({ onComplete }) => {
  const [video, setVideo] = useState(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isQuizReady, setIsQuizReady] = useState(false);

  useEffect(() => {
    const loadVideo = async () => {
      const data = await getVideo(); // Backend provides video data including the URL
      setVideo(data);
    };
    loadVideo();
  }, []);

  // Triggered when the video ends
  const handleVideoEnd = () => {
    setIsQuizReady(true); // Allow quiz after video is fully watched
    onComplete();
  };

  // Track video progress and set the played seconds
  const handleProgress = (progress) => {
    setPlayedSeconds(progress.playedSeconds);

    // Check if the user has watched 90% of the video
    if (progress.playedSeconds >= videoDuration * 0.9) {
      setIsQuizReady(true); // Allow quiz access when 90% is watched
    }
  };
  // Set video duration once loaded
  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleStartQuiz = () => {
    window.location.href = "/agent/quiz"; // Render the quiz component
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "#f8f9fa", // Light background
      }}
    >
      {video ? (
        <ReactPlayer
          url={video.url}
          controls
          playing={true} // Autoplay enabled
          width="100%" // Full width
          height="80vh" // Slightly smaller than full viewport height
          onEnded={handleVideoEnd} // Trigger quiz when video ends
          onProgress={handleProgress} // Track video progress
          onDuration={handleDuration} // Get the video duration when loaded
          style={{
            border: "2px solid #007bff", // Blue border around the video
            borderRadius: "10px", // Rounded corners
          }}
        />
      ) : (
        <p>Loading video...</p>
      )}

      {/* Optionally display the quiz start button when video is completed */}
      {isQuizReady && (
        <div className="mt-4">
          <h2 className="mb-3">Video completed! The quiz is now available.</h2>
          <button className="btn btn-success btn-lg" onClick={handleStartQuiz}>
            Start Quiz
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
