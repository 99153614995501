import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"; // Import axios for API requests
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const API_URL = "/api"; // Change to your actual backend URL

export default function QuizAppWithAttemptsLimit() {
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [quizLocked, setQuizLocked] = useState(false);
  const [error, setError] = useState("");
  const [results, setResults] = useState([]); // To store the results after submission
  const [userres, setResponses] = useState([]);
  const [questions, setQuestions] = useState([]);

  const questionsPerPage = 3;
  const totalPages = Math.ceil(questions.length / questionsPerPage);
  const { id } = useParams();
  // Fetch quiz attempts from the database and check if the quiz is locked
  useEffect(() => {
    const fetchQuizData = async () => {
      const token = localStorage.getItem("authToken");
      try {
        // Fetch quiz attempts from the database
        const response = await axios.get(
          `${API_URL}/Training/responses/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Sending the JWT token in the headers
            },
          }
        );
        if (response.status == 404) {
          setResults();
          setQuestions();
        } else {
          console.log(response.data);
          setQuestions(response.data);
          setResults(response.data.responses.$values);
        }
      } catch (err) {
        setError("Error fetching quiz data.");
      }
    };

    fetchQuizData();
  }, [id]);
  if (results) {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <h1 className="card-title text-center mb-4">Quiz Results</h1>
                <h2 className="text-center mb-4">
                  Total Score: {questions.percentageScore}%
                </h2>
                {questions.totalScore < 50 && (
                  <div className="alert alert-danger text-center" role="alert">
                    You have failed the exam. You have {3 - attempts} attempts
                    remaining.
                  </div>
                )}
                {results.map((question) => (
                  <div key={question.id} className="mb-4">
                    <h5 className="card-subtitle mb-2">
                      {question.questionText}
                    </h5>
                    <p>
                      User answer:{" "}
                      <strong>
                        {question.selectedOptionText || "Not answered"}
                      </strong>
                    </p>

                    {question.isCorrect ? (
                      <div className="alert alert-success">Correct!</div>
                    ) : (
                      <div className="alert alert-danger">Incorrect</div>
                    )}
                  </div>
                ))}
                <div className="text-center mt-4">
                  <Link className="btn btn-primary" to="/responses">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body text-center">
                <h1 className="card-title mb-4">No Results Found</h1>
                <p className="lead">
                  It seems there are no results available at the moment.
                </p>
                <p>Please try again later or contact support for assistance.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
