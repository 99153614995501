import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginForm from "./pages/LoginForm";
import TrainingVideoPage from "./pages/VideoPlayer";
import QuizPage from "./pages/QuizPage"; // Create this page for quiz
import ResultsPage from "./pages/ResultsPage"; // Create this page for quiz
import Dashboard from "./pages/Dashboard"; // Create this page for quiz
import Users from "./pages/Users"; // Create this page for quiz
import Videos from "./pages/Videos"; // Create this page for quiz
import QuizSetup from "./pages/List"; // Create this page for quiz
import QuestionList from "./pages/QuizList"; // Create this page for quiz
import Responses from "./pages/Responses"; // Create this page for quiz
import ViewResponses from "./pages/ViewResponses"; // Create this page for quiz

import EditQuestion from "./pages/EditQuestions"; // Create this page for quiz

const ProtectedRoute = ({ children, role }) => {
  // const { user } = useAuth();
  const users = JSON.parse(localStorage.getItem("sessionuser"));

  if (!users || !users.user || !users.user.role) {
    return <Navigate to="/" />;
  }
  const user = users.user.role;

  if (!user) {
    return <Navigate to="/" />;
  }

  if (role && user !== role) {
    return <Navigate to="/" />;
  }

  return children;
};
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LoginForm />} />

        <Route
          path="/agent/training"
          element={
            <ProtectedRoute role="Agent">
              <TrainingVideoPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-question/:questionId"
          element={
            <ProtectedRoute role="Admin">
              <EditQuestion />
            </ProtectedRoute>
          }
        />

        <Route
          path="/agent/quiz"
          element={
            <ProtectedRoute role="Agent">
              <QuizPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute role="Admin">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/responses"
          element={
            <ProtectedRoute role="Admin">
              <Responses />
            </ProtectedRoute>
          }
        />

        <Route path="/viewresponses/:id" element={<ViewResponses />} />

        <Route
          path="/results"
          element={
            <ProtectedRoute role="Agent">
              <ResultsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute role="Admin">
              <Users />
            </ProtectedRoute>
          }
        />

        <Route
          path="/videos"
          element={
            <ProtectedRoute role="Admin">
              <Videos />
            </ProtectedRoute>
          }
        />

        <Route
          path="/newquiz"
          element={
            <ProtectedRoute role="Admin">
              <QuizSetup />
            </ProtectedRoute>
          }
        />

        <Route
          path="/questionslist"
          element={
            <ProtectedRoute role="Admin">
              <QuestionList />
            </ProtectedRoute>
          }
        />

        {/* Other routes */}
      </Routes>
    </AuthProvider>
  );
}

export default App;
