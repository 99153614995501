import React, { useState } from "react";
import { login } from "../api/authApi"; // Assuming this is the API call
import { saveToken, saveUserSession } from "../services/authService"; // Save user session locally
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Logo from "./logo.png";
const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { saveToken, saveUserSession, onLogins, saveId } = useAuth(); // Assuming these methods are provided by AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(username, password); // Call your API login function
      saveToken(response.user.token); // Save the JWT token in localStorage
      saveId(response.user.userId);
      saveUserSession(response); // Save the full UserSession
      console.log(response.user.userId);
      // Check user role and redirect based on role
      if (response.user.role === "Agent") {
        navigate("/agent/training"); // Redirect to training video page
      } else if (response.user.role === "Admin") {
        navigate("/dashboard"); // Redirect to admin dashboard
      } else {
        onLogin(response); // Call the parent component for any additional actions
      }
    } catch (err) {
      setError(err.message || "Login failed");
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div
        className="card p-4 shadow-lg"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <div className="text-center mb-3">
          <center>
            <img
              src={Logo}
              alt="Logo"
              className="img-fluid items-center"
              style={{ maxWidth: "150px" }}
            />
          </center>
        </div>
        <h6 className="text-center mb-4">Agent Training Portal</h6>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="username" className="fw-bold">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password" className="fw-bold">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Login
          </button>
        </form>
        {error && <p className="text-danger mt-3 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default LoginForm;
