import { useState, useEffect } from "react";
import {
  DeleteUser,
  getQuestions,
  getUsers,
  submitEditUser,
  submitQuiz,
  submitUser,
  getVideos,
} from "../api/authApi";
import {
  Edit,
  Trash2,
  Search,
  ChevronLeft,
  ChevronRight,
  UserPlus,
  X,
  BarChart,
  BarChart3,
  Users,
  FileText,
  Settings,
  Menu,
  Bell,
  User,
} from "lucide-react";

import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../context/AuthContext";

const API_URL = "/api"; // Change to your actual backend URL
export default function AdminDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const loadUsers = async () => {
    const response = await getUsers();
    const users = response.users.$values;
    console.log(users);
    setUsers(users);
  };
  const logout = () => {
    // setUser(null); // Clear the user state
    localStorage.removeItem("authToken"); // Remove the authToken from localStorage
    localStorage.removeItem("sessionuser");
    navigate("/"); // Redirect the user to the homepage
  };
  const loadVideos = async () => {
    const response = await getVideos();

    const videodata = response.video.$values;
    setVideos(videodata);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${API_URL}/QuizSetup`, { questions });
        const data = response.data.questions.$values;
        setQuestions(data);
        console.log(data);
        if (response.ok) {
          const data = await response.json();
          setQuestions(data.questions);
        } else {
          // Handle error
        }
      } catch (err) {
        // Handle unexpected error
      }
    };

    fetchQuestions();
    loadUsers();
    loadVideos();
  }, []);
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`
          ${sidebarOpen ? "translate-x-0" : "-translate-x-full"}
          fixed inset-y-0 left-0 z-50 w-64 bg-gray-800 text-white transition-transform duration-300 ease-in-out
          md:relative md:translate-x-0
        `}
      >
        <div className="flex items-center justify-between p-4">
          <span className="text-2xl font-semibold">Admin Panel</span>
          <button onClick={() => setSidebarOpen(false)} className="md:hidden">
            <X size={24} />
          </button>
        </div>
        <nav className="mt-8">
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/dashboard"
          >
            <BarChart className="mr-3" size={20} />
            Dashboard
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/users"
          >
            <Users className="mr-3" size={20} /> Manage Users
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/videos"
          >
            <Users className="mr-3" size={20} /> Manage Videos
          </Link>

          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/questionslist"
          >
            <Users className="mr-3" size={20} /> Manage Questions
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/responses"
          >
            <Users className="mr-3" size={20} /> View Users Responses
          </Link>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <header className="bg-white shadow-md">
          <div className="flex items-center justify-between p-4">
            <button
              onClick={toggleSidebar}
              className="text-gray-500 focus:outline-none focus:text-gray-700 md:hidden"
            >
              {sidebarOpen ? <X /> : <Menu />}
            </button>
            <div className="flex items-center">
              <span className="text-gray-700 text-lg font-semibold">
                Welcome, Admin{" "}
                <button
                  onClick={logout}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Logout
                </button>
              </span>
            </div>
          </div>
        </header>

        {/* Dashboard Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-gray-700 text-3xl font-medium">Dashboard</h3>
            <div className="mt-4">
              <div className="flex flex-wrap -mx-6">
                <div className="w-full px-6 sm:w-1/2 xl:w-1/3">
                  <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                    <div className="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                      <Users className="h-8 w-8 text-white" />
                    </div>
                    <div className="mx-5">
                      <h4 className="text-2xl font-semibold text-gray-700">
                        {users.length}
                      </h4>
                      <div className="text-gray-500">Total Users</div>
                    </div>
                  </div>
                </div>
                <div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
                  <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                    <div className="p-3 rounded-full bg-green-600 bg-opacity-75">
                      <FileText className="h-8 w-8 text-white" />
                    </div>
                    <div className="mx-5">
                      <h4 className="text-2xl font-semibold text-gray-700">
                        {questions.length}
                      </h4>
                      <div className="text-gray-500">Total Questions</div>
                    </div>
                  </div>
                </div>
                <div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
                  <div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
                    <div className="p-3 rounded-full bg-pink-600 bg-opacity-75">
                      <BarChart3 className="h-8 w-8 text-white" />
                    </div>
                    <div className="mx-5">
                      <h4 className="text-2xl font-semibold text-gray-700">
                        {videos.length}
                      </h4>
                      <div className="text-gray-500">Training Videos</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8"></div>
            <div className="flex flex-col mt-8">
              <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Username
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Role
                        </th>

                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Training Completed
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Attempts Remaining
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Deadline Training
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user.username}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user.role}
                            </p>
                          </td>

                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <span
                              className={`relative inline-block px-3 py-1 font-semibold ${
                                user.trainingCompleted
                                  ? "text-green-900"
                                  : "text-red-900"
                              } leading-tight`}
                            >
                              <span
                                aria-hidden
                                className={`absolute inset-0 ${
                                  user.trainingCompleted
                                    ? "bg-green-200"
                                    : "bg-red-200"
                                } opacity-50 rounded-full`}
                              ></span>
                              <span className="relative">
                                {user.trainingCompleted
                                  ? "Completed"
                                  : "Not Complete"}
                              </span>
                            </span>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user.attemptsRemaining}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user.deadline}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <Link
                              to={`/viewresponses/${user.id}`}
                              className="text-blue-600 hover:text-blue-900 mr-2"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
