import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  DeleteUser,
  getQuestions,
  getUsers,
  submitEditUser,
  submitQuiz,
  submitUser,
} from "../api/authApi";
import { CustomToast } from "../components/CustomToast";
import {
  Edit,
  Trash2,
  Search,
  ChevronLeft,
  ChevronRight,
  UserPlus,
  X,
  BarChart,
  BarChart3,
  Users,
  FileText,
  Settings,
  Menu,
  Bell,
  User,
} from "lucide-react";
import axios from "axios";

const ModalType = "add" | "edit" | null;

export default function Component() {
  const API_URL = "/api"; // Change to your actual backend URL
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const usersPerPage = 5;

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${API_URL}/QuizSetup`, { questions });
        const data = response.data.questions.$values;
        setQuestions(data);
        console.log(data);
        if (response.ok) {
          const data = await response.json();
          setQuestions(data.questions);
        } else {
          // Handle error
        }
      } catch (err) {
        // Handle unexpected error
      }
    };

    fetchQuestions();
  }, []);
  const ALERT_TYPES = {
    SUCCESS: "success",
    ERROR: "error",
    NONE: null,
  };
  const filteredUsers = users?.filter(
    (user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [alert, setAlert] = useState({ type: ALERT_TYPES.NONE, message: "" });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handleEdit = (question) => {
    // setEditingQuestion(question);
    navigate(`/edit-question/${question.id}`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/QuizSetup/${id}`);

      if (response.ok) {
        setUsers(users.filter((user) => user.id !== id));
        setAlert({ type: "error", message: "Question Removed successfully" });
      } else {
        // Handle error
      }
    } catch (err) {
      // Handle unexpected error
    }
  };

  const handleAddUser = () => {
    setCurrentUser(null);
    setModalType("add");
  };

  const handleCloseModal = () => {
    setModalType(null);
    setCurrentUser(null);
  };

  const handleTestToast = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newUser = {
      username: formData.get("username"),
      role: formData.get("role"),
      passwordHash: formData.get("password"),
      trainingCompleted: formData.get("trainingCompleted") === "on",
      attemptsRemaining: parseInt(formData.get("attemptsRemaining")),
      deadline: formData.get("deadlineTraining"),
    };

    if (modalType === "add") {
      const response = await submitUser(newUser);
      setAlert({ type: "success", message: "User Added successfully" });
      setUsers([...users, newUser]);
    } else if (modalType === "edit") {
      const id = formData.get("id");
      const response = await submitEditUser(id, newUser);

      const updatedid = response?.id;
      setAlert({ type: "success", message: "Video Updated successfully" });
      setUsers(users.map((user) => (user.id === updatedid ? newUser : user)));
    }

    handleCloseModal();
  };
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`
          ${sidebarOpen ? "translate-x-0" : "-translate-x-full"}
          fixed inset-y-0 left-0 z-50 w-64 bg-gray-800 text-white transition-transform duration-300 ease-in-out
          md:relative md:translate-x-0
        `}
      >
        <div className="flex items-center justify-between p-4">
          <span className="text-2xl font-semibold">Admin Panel</span>
          <button onClick={() => setSidebarOpen(false)} className="md:hidden">
            <X size={24} />
          </button>
        </div>
        <nav className="mt-8">
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/dashboard"
          >
            <BarChart className="mr-3" size={20} />
            Dashboard
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/users"
          >
            <Users className="mr-3" size={20} /> Manage Users
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/videos"
          >
            <Users className="mr-3" size={20} /> Manage Videos
          </Link>

          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/questionslist"
          >
            <Users className="mr-3" size={20} /> Manage Questions
          </Link>
          <Link
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700"
            to="/responses"
          >
            <Users className="mr-3" size={20} /> View Users Responses
          </Link>
        </nav>
      </aside>
      {/* Main content */}
      <main className="flex-1 p-8 overflow-auto">
        <h2 className="text-2xl font-semibold mb-4">Welcome, Admin</h2>
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex flex-row justify-between w-full mb-1 sm:mb-0">
            <h2 className="text-2xl leading-tight">Questions Management</h2>
            <Link
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              to="/newquiz"
            >
              Add Questions
            </Link>
          </div>
          {alert.type && (
            <div
              className={`mt-4 p-4 rounded-md ${
                alert.type === "success"
                  ? "bg-green-100 text-green-700"
                  : "bg-red-100 text-red-700"
              }`}
              role="alert"
            >
              <p className="font-bold">
                {alert.type === "success" ? "Success" : "Error"}
              </p>
              <p>{alert.message}</p>
            </div>
          )}
          <div className="my-2 flex sm:flex-row flex-col">
            <div className="flex flex-row mb-1 sm:mb-0">
              <div className="relative">
                <select className="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option>5</option>
                  <option>10</option>
                  <option>20</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="block relative">
              <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                <Search className="h-4 w-4 fill-current text-gray-500" />
              </span>
              <input
                placeholder="Search"
                className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Question Text
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Number of Options
                </th>

                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Correct Answer
                </th>

                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <tr key={question.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {question.questionText}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {question.options.$values.length}
                    </p>
                  </td>

                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {question.options.$values
                      .filter((option) => option.isCorrect)
                      .map((option) => (
                        <li key={option.id} className="text-success">
                          {option.optionText} (Correct Answer)
                        </li>
                      ))}
                  </td>

                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      onClick={() => handleEdit(question)}
                      className="text-blue-600 hover:text-blue-900 mr-2"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(question.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstUser + 1} to{" "}
              {Math.min(indexOfLastUser, filteredUsers.length)} of{" "}
              {filteredUsers.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <button
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </main>

      {modalType && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {modalType === "add" ? "Add New User" : "Edit User"}
              </h3>
              <form className="mt-2 text-left" onSubmit={handleSubmit}>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="id"
                  type="hidden"
                  name="id"
                  defaultValue={currentUser?.id || ""}
                  required
                />
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    name="username"
                    defaultValue={currentUser?.username || ""}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="role"
                  >
                    Role
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="role"
                    name="role"
                    defaultValue={currentUser?.role || ""}
                    required
                  >
                    <option value="Admin">Admin</option>
                    <option value="Editor">Editor</option>
                    <option value="Viewer">Viewer</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="trainingCompleted"
                      defaultChecked={currentUser?.trainingCompleted || false}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700">
                      Training Completed
                    </span>
                  </label>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="attemptsRemaining"
                  >
                    Attempts Remaining
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="attemptsRemaining"
                    type="number"
                    name="attemptsRemaining"
                    defaultValue={currentUser?.attemptsRemaining || 3}
                    required
                    min="0"
                    max="3"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="deadlineTraining"
                  >
                    Deadline Training
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="deadlineTraining"
                    type="date"
                    name="deadlineTraining"
                    defaultValue={currentUser?.deadline || ""}
                    required
                  />
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {modalType === "add" ? "Add User" : "Update User"}
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
